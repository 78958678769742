import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loading = props => (
  <div style={{ height: '500px', paddingTop: '100px', textAlign: "center" }}>
      <CircularProgress />
  </div>
);

export default Loading;
