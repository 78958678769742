import React from "react";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";


import Layout from "../components/layout";
import SEO from "../components/seo";

import Loading from "../components/Miscellaneous/Loading";
import axios from "axios";
import { login } from "../helpers/auth";
import { navigate } from "gatsby";

const style = {
  textField: {
    width: "100%",
    marginTop: 10
  },
  container: {
    width: 650,
    padding: 30
  },
  button: {
    width: 150
  },
  p: {
    textAlign: "center"
  }
};

class Login extends React.Component {
  state = {
    loading: false,
    email: "",
    password: "",
    authenticationCode: "",
    error: false
  };

  verifyUser = async () => {
    if (typeof window === "object") {
      this.setState({ loading: true });

      const { email, password } = this.state;

      const logged = await login(email, password);

      if (logged) {
        navigate("/");
      } else{
        this.setState({ error: true, loading: false })
      }
    }
  };

  render() {
    return (
      <Layout showAvatar={false} showSidebar={false}>
        <SEO title={`Log Me In`} />
        <h1 style={{ textAlign: "center", padding: 30 }}>Welcome!</h1>
        {this.state.error && (
          <div
            style={{
              ...style.container,
              backgroundColor: "lightyellow",
              padding: 20,
              margin: "0 auto"
            }}
          >
            <div style={{ textAlign: "center", color: "red" }}>
              Incorrect email or password. <br />
              Please search for the original email sent to you with your
              password.
            </div>
          </div>
        )}
        {this.state.loading ? (
          <Loading />
        ) : (
            <Grid container>
              <Grid container justify="center" spacing={1}>
                <div style={style.container}>
                  <p style={style.p}>
                    Please enter your name &amp; email to log in...
                </p>
                  <TextField
                    style={style.textField}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <TextField
                    style={style.textField}
                    label="Password"
                    margin="normal"
                    variant="outlined"
                    type="password"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    style={{ marginTop: 20 }}
                  >
                    <Grid item>
                      <Button
                        style={style.button}
                        variant="contained"
                        color="primary"
                        onClick={this.verifyUser}
                      >
                        Login
                    </Button>
                    </Grid>
                    {/* <Grid item>
                    <Link to="./forgot">Forgot My Password</Link>
                  </Grid> */}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
      </Layout>
    );
  }
}

export default Login;
